<template>
  <div class="page poem">
    <div class="details" v-if="info.length>0">
      <div class="poemContent">
        <div class="img" v-if="info[0].img"><img :src="info[0].img"></div>
        <div class="poemT f56">{{info[0].poem}}</div>
        <div class="poetT f24">{{getDynasty(info[0].poet)}} · <i>
          <router-link :to="'/poet/'+getPoetId(info[0].poet)">{{info[0].poet}}</router-link>
        </i></div>
        <div class="content f32" v-html="jie(info[0].content)"></div>
        <div class="love" @click="love(info[0].id)">
          <i ref="love">{{info[0].love}}</i>
          <svg t="1707211522108" class="zan" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
               p-id="4190" width="32" height="32">
            <path d="M531.456 880.128c-15.872 15.872-41.984 15.872-57.856 0l-336.384-336.384c-100.864-100.864-100.864-264.704 0-365.568 100.864-100.864 264.704-100.864 365.568 0 100.864-100.864 264.704-100.864 365.568 0 100.864 100.864 100.864 264.704 0 365.568l-336.896 336.384z"
                  p-id="4191"></path>
          </svg>
        </div>
        <!--        <audio :src="info[0].audio" ref="music" @ended="onended"></audio>-->
        <audio :src="audio" ref="music" @ended="onended"></audio>
        <div class="tags">
          <ul>
            <li class="bf">{{selectVoice}}<i class="iconfont icon-add"></i>
              <dl>
                <dt @click="voiceType=item.text" v-for="(item,xh) in voiceTypeList" :key="xh">{{item.name}}</dt>
              </dl>
            </li>
            <li class="bf" :class="{zz:play}" @click="playVoice"><img src="@/assets/myimg/play.png"
                                                                      v-if="!play"><span
                    v-if="!play">播放</span><img src="@/assets/myimg/pause.png" v-if="play"><span v-if="play">暂停</span>
            </li>
            <li class="cur">{{getGrade(info[0].grade)}}</li>
            <li v-for="(item,xh) in tags" :key="xh">{{item}}</li>
          </ul>
        </div>
      </div>
      <div class="poemTranslation" v-if="info[0].bg">
        <div class="httitle f22">创作背景</div>
        <blockquote v-html="info[0].bg" class="f16"></blockquote>
      </div>
      <div class="poemNote" v-if="info[0].note">
        <div class="httitle f22">词句注释</div>
        <div class="content f18" v-html="jie(info[0].note)"></div>
      </div>
      <div class="poemTranslation" v-if="info[0].translation">
        <div class="httitle f22">白话译文</div>
        <div class="content f18" v-html="info[0].translation"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {love} from '@/api/api'
import axios from 'axios'

export default {
    name: 'poem',
    data() {
        return {
            grade: [],
            poet: [],
            poem: [],
            play: false,
            audio: null,
            voiceType: 'female_zhubo',
            voiceTypeList: [{
                name: '女主播',
                text: 'female_zhubo'

            }, {
                name: '男主播',
                text: 'male_zhubo'

            }, {
                name: '四川妹子',
                text: 'female_sichuan'

            }, {
                name: '东北大汉',
                text: 'male_db'

            }, {
                name: '歌手',
                text: 'male_rap'

            },],
        }
    },
    created() {

        let formData1 = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'grade',
            perpage: 10000,
        }
        let formData3 = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'poem',
            perpage: 10000,
            order: ["id DESC"]
        }
        let formData4 = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'poet',
            perpage: 10000,
            order: ["id DESC"]
        }

        if (this.$store.state.articles.grade.length === 0) {
            this.$store.dispatch('articles/getList', formData1).then((rs) => {
                this.grade = rs
            })
        } else {
            this.grade = this.$store.state.articles.grade
        }

        if (this.$store.state.articles.poem.length === 0) {
            this.$store.dispatch('articles/getList', formData3).then((rs) => {
                this.poem = rs

            })
        } else {
            this.poem = this.$store.state.articles.poem
        }
        if (this.$store.state.articles.poet.length === 0) {
            this.$store.dispatch('articles/getList', formData4).then((rs) => {
                this.poet = rs
            })
        } else {
            this.poet = this.$store.state.articles.poet
        }


    },
    watch: {
        $route: {
            handler() {
                this.$nextTick(() => {
                    if (this.info.length > 0) {
                        this.getAudio(this.info[0].content)
                    }
                })
            },
            immediate: true
        },
        voiceType: {
            immediate: true,
            handler() {
                if (this.info.length > 0) {
                    this.getAudio(this.info[0].content)
                }
            }
        }
    },
    methods: {
        getAudio(text) {
            let reg = new RegExp('<[^>]+>', 'gi');  //过滤所有的html标签，不包括内容
            let reg1 = new RegExp('([0-9]|[\u2460-\u249B])+', 'gi')
            text = text.replace(reg, '')
            text = text.replace(reg1, '')
            text = this.info[0].poem + ',' + this.getDynasty(this.info[0].poet) + ',' + this.info[0].poet + ',' + text
            console.log(text)
            axios({
                method: 'post',
                url: 'https://v2.alapi.cn/api/tts/free',
                data: {
                    token: 'CK1TIOCjS8OAYXz0',
                    text: text,
                    voice_type: this.voiceType,
                    format: 'json',
                }
            }).then((rs) => {
                this.audio = rs.data.data.audit
            });

        },
        onended() {
            this.play = false
        },
        playVoice() {
            if (this.$refs.music.paused) {
                this.play = true
                this.$refs.music.play()
            } else {
                this.play = false
                this.$refs.music.pause()
            }
        },
        love(id) {
            let sendData = {
                app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                model_name: 'poem',
                id: id,
                change_field: 'love',
                change_value: 10,
            }
            love(sendData).then((rs) => {
                this.$refs.love.innerHTML = rs.data.after_value

            })
        },
        getGrade(id) {
            let fi = this.grade.filter((el) => {
                return el.id == id
            })
            if (fi.length > 0) {
                return fi[0].title
            } else {
                return '课外'
            }
        },
        getPoetId(text) {
            let fi = this.poet.filter((el) => {
                return el.poet === text
            })
            if (fi.length > 0) {
                return fi[0].id
            } else {
                return ''
            }
        },
        getDynasty(text) {
            let poet = this.poet
            let fi = poet.filter((el) => {
                return el.poet == text
            })
            if (fi.length > 0) {
                return fi[0].dynasty
            }
        },
        jie(html) {
            let reg1 = new RegExp('([0-9]|[\u2460-\u249B])+', 'gi')
            let reg = new RegExp('(?<=href=")[^ ]+(?=")', 'gi')
            let reg2 = new RegExp('links', 'gi')
            let ah = html.match(reg)
            let z = html.match(reg1)
            if (ah) {
                for (let i = 0; i < ah.length; i++) {
                    html = html.replace(ah[i], 'links')
                }
            }
            if (z) {
                for (let i = 0; i < z.length; i++) {
                    html = html.replace(z[i], '<sup>' + z[i] + '</sup>')
                }
            }
            let ll = html.match(reg2)
            if (ll) {
                for (let i = 0; i < ll.length; i++) {
                    html = html.replace(ll[i], ah[i])
                }
            }
            return html
        },
    },
    computed: {
        tags() {
            let tags = this.info[0].tags
            let tag = []
            if (tags) {
                if (tags.indexOf('|') > -1) {
                    tag = tags.split("|")
                } else {
                    tag.push(tags)
                }

            }
            return tag
        },
        info() {
            let id = this.$route.params.id
            let fi = this.poem.filter((el) => {
                return el.id == id
            })

            return fi

        },
        selectVoice() {
            let fi = this.voiceTypeList.filter((el) => {
                return el.text == this.voiceType
            })
            return fi[0].name
        }
    }
}
</script>

